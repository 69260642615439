import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import "semantic-ui-css/semantic.min.css"
import { Grid, List, Image } from "semantic-ui-react"
import SiteLogo from "../images/logo_FoodBook.png"

const FooterComponent = () => (
  <Grid
    columns={3}
    verticalAlign="middle"
    style={{ minHeight: "250px", backgroundColor: "rgb(0, 8, 57)" }}
    stackable
    padded="vertically"
  >
    <Grid.Row>
      <Grid.Column textAlign="center" verticalAlign="middle">
        <h2 style={{ textAlign: "center", color: "#FFF" }}>OUR ADDRESS</h2>
        <p style={{ textAlign: "center", color: "#FFF" }}>
          22 Lorem Address, 2 Lorem St, City
          <br />
          lorem Ipsum, Country
        </p>
      </Grid.Column>

      <Grid.Column
        textAlign="center"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Image src={SiteLogo} style={{ width: "96px" }} />
        <h3 style={{ color: "#FFF" }}>MY FOOD BOOK</h3>
      </Grid.Column>
      <Grid.Column textAlign="center">
        <List>
          <List.Item style={{ color: "#fff" }} content="Privacy Policy" />
          <List.Item style={{ color: "#fff" }} content="Terms & Conditions" />
          <List.Item
            style={{ color: "#fff" }}
            content={
              <a style={{ color: "#ffa41b" }} href="mailto:info@myfoodbook.in">
                info@myfoodbook.in
              </a>
            }
          />
        </List>
      </Grid.Column>
    </Grid.Row>
  </Grid>
)

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div>
        <main>{children}</main>
        <footer>
          <FooterComponent />
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
