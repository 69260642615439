// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Grid, Icon, Image } from "semantic-ui-react"
import SiteLogo from "../images/logo_FoodBook.png"
import { Link } from "gatsby"

const Header = ({ siteTitle }) => (
  <header
    style={{
      backgroundColor: "#000839",
      position: "fixed",
      zIndex: "10",
      top: "0",
      width: "100%",
    }}
  >
    <div>
      {/* <h1 style={{ margin: 0 }}>
        <Link to="/">
          {siteTitle}
        </Link>
      </h1> */}
      <Grid>
        <Grid.Row style={{ height: "100px", color: "#fff" }}>
          <Grid.Column
            verticalAlign="middle"
            textAlign="center"
            width={6}
            className="menu-items-left"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                fontWeight: "bold",
              }}
            >
              <div>ABOUT</div>
              <div>ALL POSTS</div>
              <div>FEATURED</div>
            </div>
          </Grid.Column>
          {/* Mobile Burger Menu */}
          <Grid.Column
            verticalAlign="middle"
            textAlign="left"
            width="6"
            only="mobile"
          >
            <Icon
              name="bars"
              style={{ fontSize: "20px", marginLeft: "15px" }}
            />
          </Grid.Column>
          {/* End Mobile burger menu */}
          <Grid.Column
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            verticalAlign="middle"
            textAlign="center"
            width={4}
          >
            <Link to="/">
              <Image src={SiteLogo} size="mini" />
              
            </Link>
            <strong>
                MY FOOD BOOK
              </strong>
          </Grid.Column>
          <Grid.Column
            verticalAlign="middle"
            textAlign="center"
            width={6}
            className="menu-items-right"
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                fontWeight: "bold",
              }}
            >
              <div>RECENTS</div>
              <div>GALLERY</div>
              <div>USER</div>
            </div>
          </Grid.Column>
          {/* Mobile user login */}

          <Grid.Column
            verticalAlign="middle"
            textAlign="right"
            width="6"
            only="mobile"
          >
            <Icon
              name="user"
              style={{ fontSize: "20px", marginRight: "15px" }}
            />
          </Grid.Column>

          {/* MObile user login icon end */}
        </Grid.Row>
      </Grid>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
